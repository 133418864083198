.mediacover > h1 {
  text-align: center;
  font-size: xxx-large;
}
.mediacover > h5 {
  text-align: center;
}
.mediacover1 {
  /* display: flex; */
  
  background-color: aliceblue;
  /* padding: 6em 5em 3em 1em;
  margin: 1em 1em 1em 1em; */
  border-radius: 15px;
}
.mediacover1 > img {
  /* margin: -3rem 0rem 0rem 0rem; */
}
.mediacover11 {
  /* padding: 0rem 4rem 0rem 2rem; */
  /* margin-left: 3rem;
  margin-right: 2rem; */
  /* width: 50%; */
  display: flex;
  flex-direction: column; /* To stack the content vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  height: 100%; /* Ensure the div takes up the full height of its container */
  text-align: center; /* Optional: Center the text */
  padding: 16px; /* Optional: Add some padding for spacing */
  box-sizing: border-box;
  font-family: "Daikon-regular";
}
