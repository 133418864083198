.head {
  display: flex;
  /* margin: 1rem 0rem 1rem 0rem; */
  text-align: center !important;
  justify-content: center;
  font-size: xxx-large;
  /* margin-top: 70px; */
  /* padding-left: 12%; */
  font-family: "Daikon-Bold";
  font-style: normal;
  color: white;
  padding-top: 10%;
}
.para {
  text-align: center;
  font-family: "Daikon-Regular";
  /* margin-left: 6%;
  margin-right: 6%; */
  padding: 2% 5%;
  color: white;
  font-weight: 600;
  font-size: x-large;
}
.buttonmain {
  height: 50px;
  width: 155px;
  /* margin: 25px 0px 0px 575px; */
  margin-left: 44%;
  margin-top: 18px;
  margin-bottom: 18px;
  /* padding-top: ; */
  background-color: #35a848;
  color: white;
  border-radius: 10px;
  border-width: 0px;
  font-size: x-large;
  cursor: pointer;
}
.buttonmain1 {
  text-decoration: none;
  color: white;
}

.image-div {
  text-align: center;
  padding: 25px;
  padding-top: 6rem;
}
.imagemain {
  height: 200px;
}
.box1 {
  padding: 25px 0px 0px 25px;
  display: flex;
}
.box11 > p {
  padding: 8px 0px 0px 10px;
  margin: 15px 0px 0px 25px;
  font-weight: bold;
}
.box11 > p:hover {
  color: green;
  cursor: pointer;
}
.box12 > p {
  padding: 8px 0px 0px 10px;
  margin: 15px 0px 0px 25px;
  font-weight: bold;
}
.box12 > p:hover {
  color: green;
  cursor: pointer;
}
.box11 > img {
  background-color: rgb(51, 122, 183);
  margin: 2.5rem 0rem 0rem 0.5rem;
  width: 6rem;
  height: 3.5rem;
}
.box11 {
  border: 1px solid black;
  width: 30vw;
  display: flex;
  border-radius: 10px;
  border: 1px solid lightgrey;
}
.box12 > img {
  width: 6rem;
  height: 3.5rem;
  margin: 9px 30px 0px 15px;
  padding: 30px 10px 65px 0px;
}
.box12 {
  border: 1px solid black;
  width: 30vw;
  display: flex;
  margin: 0px 0px 0px 50px;
  border-radius: 10px;
  border: 1px solid lightgrey;
}
.box12 > h1 {
  font-size: 2.5rem;
  padding: 35px 20px 0px 20px;
  color: green;
}
.box22 > img {
  background-color: rgb(51, 122, 183);
  margin: 2.5rem 0rem 0rem 0.5rem;
  width: 6rem;
  height: 3.5rem;
}
.box22 {
  border: 1px solid black;
  width: 30vw;
  display: flex;
  margin: 0px 0px 0px 50px;
  border-radius: 10px;
  border: 1px solid lightgrey;
}
.box22 > h1 {
  font-size: 2.5rem;
  padding: 35px 20px 0px 20px;
  color: green;
}
.box22 > p {
  padding: 8px 0px 0px 10px;
  margin: 15px 0px 0px 20px;
  font-weight: bold;
}
.box22 > p:hover {
  color: green;
  cursor: pointer;
}
.boxes {
  display: flex;
  justify-content: center;
  padding: 0px 12px;
  /* flex-wrap: wrap; */
}
.boxes2 {
  background-color: #e7eefe;
  /* margin: 25px 0px 0px 10px;
    border-radius: 10px ; */
  /* margin: 1rem 1rem 0rem 2rem; */
  border-radius: 1rem;
  font-family: "Daikon-regular";
  position: static;
}
.boxes21 {
  height: 100%;
  border: 1px solid black;
  width: 22vw;
  border-radius: 10px;
  border: 1px solid lightgrey;
  overflow: none;
}
.boxes21 > img {
  width: 5rem;
  height: 10rem;
}
.boxes21 > h1 {
  /* padding: 30px 0px 0px 75px; */
  text-align: center;
  font-size: x-large;
}
.boxes21 > div > p {
  /* padding: 10px 0px 0px 40px; */
  text-align: center;
}
.buttmycard {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.butmycard {
  background-color: #14213d;
  color: white;
  text-align: center;
  height: 2.5rem;
  width: 15rem;
  text-align: center;
  font-size: x-large;
  border: none;
  border-radius: 7px;
  font-weight: bold;
  cursor: pointer;
}
.buttmycard > img {
  height: 15rem;
  width: 15rem;
  margin: 3rem 0rem 0rem 1rem;
}
.bottombutton {
  /* margin: 30px 0px 20px 500px; */

  border: none;
  border-radius: 9px;
  background-color: white;
  color: #14213d;
  border: 2px solid #14213d;
  height: 2.5rem;
  width: 12.5rem;
  font-size: 1.5rem;
  font-family: "Daikon-regular";
}
.bottombutton:hover {
  border: 2px solid #35a848;
}

.searchbar {
  /* position: relative; */
  width: 100%;
  /* padding: 5em 0em 2em 14em; */
  /* padding: 1rem 0; */
  display: flex;
  justify-content: end;
  margin: 2rem 0rem 1.5rem -2rem;
}

.inputmain {
  width: 28%;
  text-align: start;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 2.5rem;
  /* border-radius: 10px; */
  font-size: medium;
  /* padding: 0rem 0rem 0rem 0.5rem; */
  padding: 0 0.5rem;
  font-family: "Daikon-regular";
  /* display: flex; */
}

.inputmain:focus {
  /* border-width: 3px;
  border-color:#35a848; */
  border: 3px solid #35a848;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
}
.inputmain:active {
  /* border-width: 3px;
  border-color:#35a848; */
  /* border: 3px solid #35a848; */
  border-top: none;
  border-left: none;
  border-right: none;
}

/* .SearchbarMain {
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
} */

.buttonNav {
  height: 40px;
  width: 74px;
  margin: 2.5rem 0rem 0rem 1rem;
  text-align: center;
  color: white;
  background-color: black;
  border-radius: 10px;
}

.image {
  height: 20px;
  position: absolute;
  margin: 3.3rem 0rem 0rem 60rem;
  /* margin: 51px 0px 0px -818px; */
  /* margin: 3.2rem 0rem 0rem -48.5rem; */
}
.cardviewmorebutton {
  text-decoration: none;
  color: black;
}
.cardviewmorebutton:hover {
  color: green;
}
.mainbodycard {
  margin: 0rem 0rem 0rem 1rem;
  margin: 2rem 0rem 0rem 2rem;
}

.mainbodycard1 {
  text-decoration: none;
  color: black;
  font-size: large;
  font-family: "Daikon-Regular";
}
.mainbodycard1:hover {
  color: green;
}
.box12image > img {
  background-color: #337ab7;
  margin: 2.5rem 0rem 0rem 0.5rem;
  width: 6rem;
  height: 3.5rem;
}

.carouselDiv {
  transition: box-shadow 1s;
}

.carouselDiv:hover {
  /* padding-top: 12px; */
  box-shadow: 3px 3px #35a848;
  cursor: pointer;
}

.owlBox{
  max-width: 1200px; /* Optional: To restrict the width */
}

.square {
  width: 10rem;
  height: 10rem;
  position: relative;
  margin: auto;
}
.square::before,
.square::after {
  content: "";
  position: absolute;
  background-color: black;
}
.square::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: -1; /* Push the line behind the dot */
}
.square::after {
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  z-index: -1; /* Push the line behind the dot */
}
.left-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: black;
}
@media only screen and (max-width: 768px) {
  .square {
    width: 8rem;
    height: 10rem;
    position: relative;
    margin: auto;
  }
  .square2 {
    width: 50px;
    height: 150px;
    position: relative;
    margin: auto;
    transform: scaleX(-1); /* Flip the square horizontally */
  }

}

.dot {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #ed3125;
  border-radius: 50%;
}
.top-dot {
  top: -3px;
  right: -3px;
}
.bottom-dot {
  bottom: -3px;
  right: -3px;
}
.number {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #ed3125;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  /* Inner circle */
  box-sizing: border-box; /* Include border in the width/height calculation */
}
.number-top {
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
}
.number-bottom {
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}
.outer-circle {
  width: 50px;
  height: 50px;
  border: 2px solid rgb(242, 231, 231); /* Outer circle */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translate(-50%, -50%);
  z-index: -1; /* Behind the numbers */
  box-sizing: border-box; /* Include border in the width/height calculation */
}

.left-text {
  position: absolute;
  left: -21rem; /* Adjusted for spacing */
  right: 200px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  width: 20rem;
}

.center-text {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: rgb(82, 72, 72);
  font-weight: 300px;
}

.square2 {
  width: 150px;
  height: 150px;
  position: relative;
  margin: auto;
  transform: scaleX(-1); /* Flip the square horizontally */
}
.square2::before,
.square2::after {
  content: "";
  position: absolute;
  background-color: black;
}
.square2::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: -1; /* Push the line behind the dot */
  transform: scaleX(-1); /* Flip the line horizontally */
}
.square2::after {
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  z-index: -1; /* Push the line behind the dot */
  transform: scaleX(-1); /* Flip the line horizontally */
}
.left-line2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: black;
  transform: scaleX(-1); /* Flip the line horizontally */
}
.dot2 {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: rgb(57, 95, 210);
  border-radius: 50%;
  transform: scaleX(-1); /* Flip the dot horizontally */
}
.top-dot2 {
  top: -3px;
  right: -3px;
}
.bottom-dot2 {
  bottom: -3px;
  right: -3px;
}
.number2 {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: rgb(57, 95, 210);
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  box-sizing: border-box; /* Include border in the width/height calculation */
  transform: scaleX(-1); /* Flip the number horizontally */
  left: 50%; /* Center the number horizontally */
  top: 50%; /* Center the number vertically */
  transform-origin: center; /* Set the transform origin to center */
  transform: translate(-50%, -50%) scaleX(-1); /* Apply the flip transformation and re-center the number */
}
.number-top2 {
  left: 0px;
} /* Adjust the position for the top number */
.number-bottom2 {
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
} /* Adjust the position for the bottom number */
.outer-circle2 {
  width: 50px;
  height: 50px;
  border: 2px solid rgb(242, 231, 231); /* Outer circle */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 67%; /* Center the circle horizontally */
  transform: translate(-50%, -50%) scaleX(-1); /* Flip the circle horizontally */
  z-index: -1; /* Behind the numbers */
  box-sizing: border-box; /* Include border in the width/height calculation */
}
.left-text2 {
  position: absolute;
  left: -515px;
  right: 280px;
  top: 20%;
  height: 20px;
  width: 20rem;
  transform: translateX(50%) scaleX(-1); /* Flip the text horizontally */
  font-size: 14px;
  direction: ltr; /* Flip text direction */
  unicode-bidi: bidi-override; /* Maintain readability */
}

.center-text2 {
  position: absolute;
  top: 50%;
  left: 50%; /* Adjusted for the flipped position */
  transform: translate(-50%, -50%) scaleX(-1); /* Flip the text horizontally */
  font-size: 20px;
  color: rgb(82, 72, 72);
  font-weight: 300px;
}

.tophalf1 {
  display: flex;
  margin-left: -2px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.bottomhalf1 {
  display: flex;
  justify-content: center;
  padding: 2rem 0rem 0rem 0rem;
  font-family: "Daikon-Regular";
  flex-wrap: wrap;
}

.lowerhalf1 {
  padding-top: 40px;
  padding-right: 10px;
}

.list1 {
  list-style-type: none;
  position: relative;
}

.anc1 {
  text-decoration: none;
  display: flex;
  justify-content: center;
  color: white;
  margin: 0rem 0.5rem;
  font-size: larger;
  font-weight: 600;
}

.anc1:hover {
  color: blue;
}

.anc1::after {
  content: "";
  position: absolute;
  background-color: blue;
  left: 0;
  bottom: -0.3rem;
  height: 3px;
  width: 0;
  transition: 0.4s ease-in-out;
}

.anc1:hover::after {
  width: 100%;
}

.dropdown1 {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1;
}

.list1:hover .dropdown {
  display: block;
}

.dropdown-item1 {
  padding: 0.5em 1em;
  text-decoration: none;
  color: black;
  display: block;
}

.dropdown-item1:hover {
  background-color: #f0f0f0;
}

.linenav1 {
  width: 50rem;
  margin: 0rem 0rem 0rem 24rem;
}

/* Responsive styling */
@media (max-width: 1024px) {
  .tophalf1 {
    justify-content: space-evenly;
    flex-direction: column;
  }

  .bottomhalf1 {
    flex-direction: column;
    padding: 1rem 0;
  }

  .anc1 {
    margin: 0.5rem 0;
  }

  .linenav1 {
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .tophalf1 {
    flex-direction: column;
    align-items: center;
  }

  .bottomhalf1 {
    flex-direction: column;
    padding: 1rem 0;
  }

  .lowerhalf1 {
    padding-top: 20px;
  }

  .anc1 {
    font-size: small;
    margin: 0.5rem 0;
  }

  .list1 {
    width: 100%;
  }

  .dropdown1 {
    position: relative;
    width: 100%;
  }

  .linenav1 {
    margin: 0 auto;
    width: 90%;
  }
}

@media (max-width: 480px) {
  .tophalf1 {
    flex-direction: column;
    padding: 0;
  }

  .bottomhalf1 {
    padding: 1rem 0;
  }

  .anc1 {
    font-size: 0.9rem;
  }

  .linenav1 {
    width: 100%;
  }

  .left-text2 {
    text-align: center;
    position: absolute;
    left: -177px;
    right: 280px;
    top: 0%;
    height: 20px;
    width: 7rem;
    transform: translateX(50%) scaleX(-1); /* Flip the text horizontally */
    font-size: 13px;
    direction: ltr; /* Flip text direction */
    unicode-bidi: bidi-override; /* Maintain readability */
  }
  .left-text {
    text-align: center;
    position: absolute;
    left: -7.2rem; /* Adjusted for spacing */
    right: 200px;
    top: 66%;
    transform: translateY(-50%);
    font-size: 13px;
    width: 6.7rem;
  }
}

/* Main Banner */
/* .main-banner {
  background-image: url("../../assets/images/globe.jpg");
  width: 100%;
  height: 22rem;
  background-size: cover;
  background-position: center;
  text-align: center;
  opacity: 1;
}

.main-banner h1 {
  font-size: 2.6rem;
  font-family: "Daikon-Bold";
  font-style: normal;
  color: white;
  padding-top: 5rem;
  margin: 0;
}

.main-banner p {
  font-family: "Daikon-Regular";
  padding: 2% 5%;
  color: white;
  font-weight: 600;
  font-size: x-large;
} */

/* .main-banner {
  background-image: url("../../assets/images/carousel_one/1.webp");
  width: 100%;
  height: 40rem;
  background-size: cover;
  background-position: center;
  text-align: center;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
}

.main-banner h1 {
  font-size: 2.6rem;
  font-family: "Daikon-Bold";
  font-style: normal;
  color: white;
  margin: 0;
}

.main-banner p {
  font-family: "Daikon-Regular";
  margin-top: 1rem;
  padding: 0 2rem;
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
} */

/* Responsive Design */
/* @media (max-width: 768px) {
  .main-banner {
    height: auto;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .main-banner h1 {
    font-size: 2rem;
  }

  .main-banner p {
    font-size: 1rem;
    padding: 0 1rem;
  }
}

@media (max-width: 480px) {
  
  .main-banner h1 {
    font-size: 1.6rem;
  }

  .main-banner p {
    font-size: 0.9rem;
  }
} */

/* 
.main-banner {
  width: 100%;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.main-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
} */


.main-banner {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.main-banner img {
  width: 100%;
  /* height: 100%; */
  max-height: 570px;
  object-fit: cover;
}

.banner-content {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: green; /* White text for contrast */
  text-align: center;
  padding: 1rem;
  /* background-color: rgba(0, 0, 0, 0.5);  */
  border-radius: 8px; /* Optional: Rounded corners */
  width: 80%; /* Adjust as needed */
}

.banner-content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.banner-content p {
  font-size: 1.4rem;
  line-height: 1.5;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .main-banner img{
    height: 320px;

  }
  .banner-content {
    position: absolute;
    top: 35%;
  }
  .banner-content h1 {
    font-size: 2.4rem;
  }

  .banner-content p {
    font-size: 1.1rem;
    line-height: 1.2;  }
}

.break-after {
  white-space: nowrap;
}

@media (min-width: 769px) {
  .break-after::after {
    content: ''; /* Acts as a placeholder for the break */
    display: block; /* Forces the break after "financial" */
  }
}


/* Owl carousel */
.owl-next {
  display: none !important;
}
.owl-prev {
  display: none !important;
}

.view-more {
  border-radius: 14px !important;
}

.company-btn {
  /* background-color: orange !important; */
  background-color: rgb(2, 135, 2) !important;
  color: white !important;
}
.company-btn:hover {
  background-color: rgb(0, 88, 0) !important;
  /* background-color: rgb(227, 149, 4) !important; */
  color: white !important;
}

/* view more btn */
.view-more {
  background-color: white !important;
  box-shadow: none !important;
  color: black !important;
}
