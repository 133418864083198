/* .blocks {
  display: flex;
  flex-direction: row;
  background-color: #14213d;
  color: white;
  overflow: hidden;
  padding-bottom: 1%;
  justify-content: space-between;
}
.block1 {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem 0rem 1rem;
}
.block1 > img {
  background-color: white;
  width: 10rem;
  height: 3rem;
  margin: 1rem 1rem 0rem 2rem;
}
.block1 > p {
  width: 35rem;
  padding: 1rem 0rem 0rem 2rem;
  font-size: medium;
}
.app {
  padding: 1rem 0rem 0rem 1rem;
  width: 5rem;
}

.anchor {
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 200px;
  margin: 0px 10px 0px 100px;
}
.insideanch {
  font-size: medium;
  text-decoration: none;
  color: white;
  flex-direction: column;
  padding: 0.7rem 0rem 0rem 0rem;
  transition: 0.7s ease-in-out;
  transform: translateX(0rem);
}
.insideanch:hover {
  color: green;
  transform: translateX(1.5rem);
}
.block2 {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem 0rem 1rem;
}
.block3 {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem 0rem 1rem;
}
.block4 {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem 0rem 1rem;
  text-align: left;
}

.anchorfoot {
  padding: 2rem 0px 0px 6rem;
  font-size: larger;
}
.anchorfoot1 {

  font-size: larger;
}

.pfooter {
  font-size: medium;
  display: flex;


}
.footoffice {
  padding: 10px 0px 0px 50px;
  font-size: x-large;
}
.botfoot {
  text-align: center;
  background-color: #2f3c5b;
  color: white;
  height: 2rem;
  font-size: larger;
  padding: 4px;
}
.iconfooter {
  padding: 0rem 0rem 0rem 1rem;
  justify-content: center;
}
.icon {
  padding: 0rem 0.5rem 0rem 2rem;
  cursor: pointer;
}
#footmail{
  display: flex;
  justify-content: center;
} */


/* new css */

.mainFooter {
  background-color: #14213d;
  color: white;
  padding: 2rem;
}

.blocks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
}

.block2 {
  text-align: center;
padding-top: 25px;
flex: 0.4;
padding-right: 22px;
}

.block1 {
  flex: 0.7;
}


.block4 {
  flex: 0.8;
  min-width: 250px;
  margin: 1rem 0;
}

.block1 img {
  width: 150px;
  height: auto;
  margin-bottom: 1rem;
  background-color: white;
}



.block1 p {
  font-size: 0.95rem;
  line-height: 1.5rem;
}

.anchorfoot,
.anchorfoot1 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.anchor {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.insideanch {
  font-size: 1rem;
  color: white;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

.insideanch:hover {
  color: #0df200;
  transform: translateX(0.5rem);
}

.pfooter {
  font-size: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .pfooter {
 
    display: block;
  }
}

.iconfooter {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.icon {
  color: white;
  cursor: pointer;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #0df200;
}

.botfoot {
  text-align: center;
  background-color: #2f3c5b;
  padding: 1rem 0;
  font-size: 1rem;
  margin-top: 2rem;
}
