.fundraisetop>h1{
    font-size: xx-large;
    font-weight: 700;
    /* padding: 2rem 0rem 0rem 2rem; */
    /* display: flex;
    justify-content: center; */
    text-align: center;
}
.fundraisetop>p{
    /* font-size: large; */
    /* padding: 0rem 4rem 0rem 4rem; */
    /* display: flex;
    justify-content: center; */
    text-align: center;
}
.fundraisetop>img{
    width: 20em;
    padding: 3em 0em 0em 0em;
    margin: 1em 0em 1em 30em;
}
.letsbuild{
    background-color: #EEF5F9;
    display: flex;
    height: 25em;
    margin: 2em 1em 2em 1em;
    border-radius: 15px;
}
.letsbuild>img{
    width: 30em ;
    height: 20em;
    margin: 3em 0em 1em 20em;
}
.letsbuild1{
    padding: 0em 0em 0em 2em ;
}
.letsbuild11{
    display: flex;
}
/* .letsbuild11>p{
    padding: 0em 0em 0em 0em;
    padding: 0.4rem 0rem 0rem 0.5rem;
} */ 
.raisefund{
    display: flex;
    padding: 4em 1em 0em 1em;
}
.raisefund>img{
    width: 38em;
}
.raisefund1{
    padding: 2em 1em 0em 10em;
}
.raisefund1>h1{
    font-weight: 700;
}
.raisingsme{
    display: flex;
    /* padding: 2rem 4rem 2rem 1rem ; */
}
.raisingsme>img{
    width: 25rem;
    height: 33rem;
    padding: 0.5rem;
    object-fit: contain;
}
.raisingsme1>p{
    /* padding: 0em 3em 1em 0em; */
}
.raisingsme1>h1{
    font-weight: 700;
}
.raisefundvia>h1{
    /* padding: 2em 0em 0em 9em; */
    font-weight: 700;
}
.raisefundvia>p{
    /* padding: 2em 0em 0em 28em; */
}
.bigboxipofund{
    display: flex;
    justify-content: space-evenly;
    /* padding: 5em 0em 0em 1em; */
}
#greendotfund{
    margin: 0em 0em 0em 2em ;
}
#greendotfund3{
    margin: 0em 0em 0em 4em ;
}
.fundraisingstories{
    background-color: #EEF5F9;
    height: 25em;
    margin: 2em 0em 2em 0em ;
}
.fundraisingstories>h1{
    padding: 3em 0em 0em 8em;
    font-weight: 700;
}
.fundraisingstories1{
    display: flex;
    justify-content: space-evenly;
    margin: 5em 1em 0em 1em ;
}
.fundraisingstories11{
    background-color: white;
    border-radius: 10px;
    width: 10em;
    height: 7em ;
}
.fundraisingstories11>img{
    width: 5em;
    padding: 2em 2em 0em 3em ;
}
#fundraisingstories114{
    width: 10em;
    height: 7em ;
    /* padding: 1em 0em 0em 0em; */
}
#fundraisingstories115{
    width: 10em;
    height: 7em ;
    /* padding: 1em 0em 0em 0em; */
}
.reach{
    display: flex;
}
#para2{
    margin: 0em 0em 0em 7em;
    font-size: x-large;
    color: darkgray;
}
#para1{
    font-size: x-large;
}
.reach11{
    display: flex;
    flex-direction: column;
}
.reach11>input{
    width: 35em;
    margin: 0.3em 0em 1em 0em;
    border: 2px solid lightgray;
    border-radius: 8px;
    height: 3em;
}
.reach11>label{
    font-weight: 700;
}
.reach1{
    display: flex;
}
.reachmain{
    padding: 4em 0em 2em 20em;
}
.reachmain>h1{
    padding: 0em 0em 2em 4em ;
}
.reachmain>hr{
    margin: 0em 30em 1em 0em;
}
#reach111{
     margin: 0em 1em 2em 0em;
}
#reach111>input{
    width: 17em;
}
.accordianraisingfunds>h1{
    margin: 2em 0em 0em 7em;
    font-size: xxx-large;
}
.accordianraisingfunds>p{
    margin: 1em 1em 3em 23em;
}

.valuefundraise{
    /* width: 13rem; */
    width: 4rem;
}
.valuefundpara{
    padding-left: 1rem;
}

.threefundraise{
    padding: 0rem 0rem 0rem 0.5rem;
}

.bodysmeloans{
    display: flex;
    justify-content: space-evenly;
    padding: 1rem 1rem 3rem 1rem;
}

.headingsmeloans{
    text-align: center;
    padding: 2rem 1rem 1rem 1rem;
}

.buysellbox{
    font-family: "Daikon-regular";
    padding-top: 2rem;
}

.buysellbox>h1{
    text-align: center;
}

.buysellbox>p{
    text-align: center;
    padding: 0rem 4rem;
}

.buysellboxtop{
    display: flex;
    margin: 1rem 1rem 1rem 1rem;
    border-radius: 15px;
}

.bodybuysell{
    padding: 1rem;
    display: flex;
}

.buysellcard{
    padding: 1rem;
}

.bodybuyselltop{
    display: flex;
    justify-content: space-evenly;
}

.bodybuyselltop>img{
    width: 40rem;
    /* height: 20rem; */
    object-fit: contain;
    padding: 1rem;
}

.fundraiseheading3{
    text-align: center;
}

.fundraisedisplay{
    display: flex;
}

.mainheadfundraise{
    padding: 0rem 1rem 0rem 1rem;
    width: 6rem;
}

.peinvesthead{
    padding: 2rem 0rem;
    display: flex;
    flex-wrap: wrap;
}

.peinvestcardtop{
    padding: 1rem;
}

.imagetopbuysell{
    padding: 1rem 0rem;
}
.imagetopbuysell>img{
    height: 25rem;
    /* object-fit: contain; */
}