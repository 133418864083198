/* 
  
.contact-banner {
    background-image: url("../../assets/images/contact1.jpg");
    background-size: cover;
    background-position: center;
    height: 400px;
  }
  
  .contact-title {
    color: white;
    font-size: 2.5rem;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  
  .contact-container {
    margin-top: 2rem;
    padding: 2rem 1rem;
  }
  
  .contact-heading {
    margin-bottom: 1rem;
    font-weight: bold;
  }
  
  .contact-description {
    margin-bottom: 2rem;
    color: #555;
  }
  
  .contact-form {
    max-width: 600px;
    margin: auto;
  }
   */

/* Contact.css */
.contact-banner {
  background-image: url("../../assets/images/carousel_one/contact.webp");
  background-size: cover;
  background-position: center;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-title2 {
  color: white;
  font-size: 2.8rem !important;
  font-weight: 700;
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.7);
  text-align: center;
  padding: 0 1rem;
}

.contact-main-container {
  margin-top: 2rem;
  padding: 4rem 2rem;
  color: white ;
 
}

.main-div{
  background-color: #06407F;
}

.contact-heading {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  
}

.contact-description {
  font-size: 1.1rem;
  /* color: #555; */
  color: white ;

  margin-top:1.7rem !important;
  margin-bottom: 2rem;

  line-height: 2;
  
}

.contact-info {
  /* background: #f9f9f9; */
  background: #002E5F;
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 4rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

}

.contact-info h6 {
  font-size: 1rem;
  /* color: #333; */
  color: white;
  margin-bottom: 0.5rem;
}

.form-container {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

button[type="submit"] {
  font-size: 1rem;
  padding: 0.8rem;
  text-transform: capitalize;
  background: linear-gradient(90deg, #6a11cb, #2575fc);
}
