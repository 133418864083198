.Steps{
  padding-bottom: -4rem;
  padding-top: 2rem;
}

.h1dash {
  font-weight: 700;
}
.bigboxipo {
  display: flex;
  justify-content: space-evenly;
  padding: 8em 0em 0em 1em;
}
.greendot {
  background-color: green;
  /* padding: 0.5em 0.5em 0em 0.5em; */
  border: 1px solid green;
  border-radius: 50px;
  text-align: center;
  height: 2em;
  width: 2em;
  color: white;
  font-weight: 700;
}

.textipo {
  margin: 1.5em 0em 0em 0em;
  font-weight: 700;
}

#textipo2 {
  margin: 1.5em 0em 0em -2em;
  font-weight: 700;
}
.lowdash {
  height: 30rem;
  width: 50rem;
}
.sidedash {
  display: flex;
  padding: 5rem;
}
.lowsidedash {
  margin: 3em 5em 0em 2em;
}
.row1 {
  text-align: center;
  background-color: #d5e9fa;
  border-radius: 15px;
  padding: 40px;
  font-family: "Daikon-regular";
}
.row1 > h1 {
  font-weight: 600;
}
.row1 > p {
  font-size: large;
}
.youget {
  display: flex;
  justify-content: center;
  font-family: "Daikon-regular";
  margin: 8em 0em 0em 1em;
}
.youget1 {
  width: 20em;
  margin: 3em 2em 0em 2em;
}
.youget2 {
  display: flex;
  justify-content: space-evenly;
}
.youget2img {
  height: 3em;
  padding: 0em 0em 0em 6em;
  margin: 2em 0em 1em 1em;
}
.youget22 {
  background-color: aliceblue;
  margin: 0em 1em 0em 0em;
  height: 20em;
  width: 18em;
  position: relative;
  transition: top ease 1s;
}
.youget22 > h5 {
  /* padding: 1em 0em 0em 7em; */
  text-align: center;
}
.youget22 > p {
  text-align: center;
}
.youget22h5 {
  /* padding: 1rem 0rem 0rem 5rem; */
  font-size: large;
}
.youget22:hover {
  top: 2em;
}

.outeryougetbutton {
  text-align: center;
  margin-top: 3rem;
}

.yougetbutton {
  height: 4rem;
  width: 60rem;
  color: white;
  background-color: #14213d;
  font-family: "Daikon-regular";
  font-size: x-large;
  border-radius: 20px;
}
.accordion {
  text-align: center;
  font-family: "Daikon-regular";
  padding-top: 4rem;
}
.accordionsell {
  text-align: center;
  font-family: "Daikon-regular";
}
.textyoutube {
  overflow: hidden;
  height: 35rem;
  padding-top: 2rem;
}
.textyoutube1 {
  height: 25rem;
  background-color: #14213d;
  color: white;
  padding: 0rem 0rem 0rem 15rem;
  position: absolute;
  width: 55rem;
  margin: 0rem 1rem 0rem 1rem;
  border-radius: 20px;
}
.reactplayer {
  width: 60rem;
  height: 25rem;
  position: absolute;
  overflow: hidden;
  z-index: 1;
  margin: 6rem 0rem 0rem 8rem;
}
#navup1h {
  padding: 0rem 0rem 0rem 3rem;
  font-family: 'Daikon-regular';
}
.navup1 > h4 {
  padding: 0rem 0rem 0rem 2rem;
  font-family: 'Daikon-regular';
}
#navup1img {
  padding: 2rem 0rem 0rem 4rem;
}
.navup1 > img {
  padding: 2rem 0rem 0rem 2.5rem;
   font-family: 'Daikon-regular';
}
.navup > p {
  text-align: center;
   font-family: 'Daikon-regular';
}
.navup {
  display: flex;
  justify-content: space-evenly;
  background-color: #e1ecf6;
  margin: 2em 1em 1em 1em;
  border-radius: 15px;
   font-family: 'Daikon-regular';
}
#navup12 {
  padding: 0em 10em 0em 5em;
  font-family: 'Daikon-regular';
}
.formup > p {
  font-size: large;
  text-align: center;
}
.formup > p > a {
  text-decoration: none;
  color: green;
}
/* .formup>h1{
    font-weight: 700;
    margin: 1em 0em 0em 12em;
} */
.formsellshares {
  font-weight: 700;
  text-align: center;
  font-family: "Daikon-regular";
  /* margin: 1rem 0rem 0rem 33rem; */
}
.input {
  display: flex;
  /* margin: 0rem 0rem 0rem 10rem; */
}
.input1 > input {
  width: 30rem;
  height: 2.5rem;
  border-radius: 10px;
  /* margin: 0.5rem 0rem 0rem 0rem; */
  border: 1px solid lightgray;
  outline: none;
}
.input1 > input:focus {
  border-color: cornflowerblue;
  background-color: whitesmoke;
  outline: none;
}

.input2 {
  /* margin: 4em 0em 2em 4em; */
  font-size: larger;
  display: flex;
  flex-direction: column;
}
.input2 > select {
  width: 10em;
  border-radius: 10px;
  border: 1px solid lightgray;
  font-size: medium;
}
.input1 {
  /* margin: 1em 1em 1em 1em; */
  display: flex;
  flex-direction: column;
}
.input1 > label {
  font-size: large;
  font-weight: 500;
}
.input3 {
  display: flex;
  flex-direction: column;
  /* margin: 3rem 0rem 1rem 10.8rem; */
}
.input3 > label {
  font-size: larger;
  font-weight: 500;
}
.input3 > input {
  width: 63rem;
  height: 6rem;
  border-radius: 10px;
  border: 1px solid lightgray;
  /* margin: 0.5rem 0rem 0rem 0rem; */
}
.input3 > input:focus {
  border-color: cornflowerblue;
  outline: none;
}
.inpmain {
  /* margin: 0rem 0rem 0rem 5rem; */
}
.formbutton {
  /* margin: 1rem 0rem 2rem 36rem; */
  width: 10rem;
  height: 2.5rem;
  color: white;
  background-color: green;
  border: 2px solid green;
  border-radius: 10px;
  font-size: large;
}

/* ContactForm.css */

/* Container Styles */
.form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

/* Heading Styles */
.form-container h2 {
  margin-bottom: 30px;
  text-align: center;
  color: #333333;
}

/* Form Group Styles */
.form-group {
  margin-bottom: 20px;
}

/* Label Styles */
.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #555555;
}

/* Input Styles */
.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"],
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus,
.form-group input[type="tel"]:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #4caf50;
}

/* Textarea Styles */
.form-group textarea {
  height: 150px;
  resize: none;
}

/* Select Styles */
.form-group select {
  appearance: none;
  padding: 12px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  background-color: #ffffff;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="%23555555" d="M16.6 5.3l-6.6 8.2-6.6-8.2c-.3-.4-.9-.4-1.2 0-.3.4-.1 1 .5 1.5l7.3 9.1c.2.2.5.3.7.3s.5-.1.7-.3l7.3-9.1c.7-.9.8-2.1.5-2.5-.4-.4-1-.4-1.3 0z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 14px;
  cursor: pointer;
}

/* Submit Button Styles */
.form-group input[type="submit"] {
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-group input[type="submit"]:hover {
  background-color: #45a049;
}

/* dataflow */
.data-flow-container {
  width: 80%;
  margin: 50px auto;
}

.step-indicator {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.step {
  display: flex;
  align-items: center;
}

.step-icon {
  width: 60px;
  height: 60px;
  background-color: #f0f0f0;
  border-radius: 50%;
  color: #007bff;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  box-shadow: 0px 0px 10px rgba(0, 123, 255, 0.3);
  transition: all 0.3s ease;
}

.step:hover .step-icon {
  transform: scale(1.1);
  box-shadow: 0px 0px 15px rgba(0, 123, 255, 0.5);
}

.step-label {
  font-size: 20px;
  font-weight: bold;
  color: #555;
  margin-right: 20px;
}

.arrow {
  font-size: 5rem;
  margin-left: 2rem;
  margin-bottom: 20px !important;
  color: #007bff;
}
