/* .commoncardtop{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.commoncardInside{
    width: 45%;
    border: 1px solid gray;
} */



/* .commoncardtop {
    display: flex;
    justify-content: space-around;
    padding: 2rem;
  } */

  /* .commoncardtop1 {
    display: flex;
    justify-content: space-around;
    padding: 1rem 2rem 1rem 2rem;
  } */
  
  .card-image {
    width: 100%;
    height: 12rem;
    object-fit: contain;
    margin-bottom: 1rem;
  }
  .commoncardInside {
    background: #fff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px; /* Limits card width */
    min-height: 330px;
    margin: 0 auto; /* Centers cards */
  }
  
  .card-heading {
    font-size: 1.5rem;
    margin-bottom: 16px;
    color: #333;
  }
  
  .card-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .card-list li {
    margin-bottom: 12px;
    font-size: 1rem;
    color: #555;
  }
  
  @media (max-width: 600px) {
    .commoncardInside {
      padding: 12px;
      max-width: 100%; /* Full width on mobile */
    }
  
    .card-heading {
      font-size: 1.25rem;
    }
  
    .card-list li {
      font-size: 0.9rem;
    }
  }
  