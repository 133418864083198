.cardpoly1{
    display: flex;
}
.cardpoly11{
    display: flex;
    flex-direction: column;
    border:1px solid black;
}

.fontFamily{
    font-family: 'Daikon-regular' ;
}