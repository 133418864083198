/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@font-face {
  font-family: "Dakon";
  src: local("Dakon"),
    url(./assets/font/Dakon.otf) format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(./assets/font/Inter-Bold.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Daikon-Bold";
  src: local("Daikon-Bold"),
    url(./assets/font/Daikon-Bold.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Daikon-Regular";
  src: local("Daikon-Regular"),
    url(./assets/font/Daikon-Regular.ttf) format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */







/* index.css */


@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: "Montserrat", sans-serif;
}


body, h1, h2, h3, h4, h5, h6, p, span, div, button, input, textarea {
  font-family: "Montserrat", sans-serif !important;
}

































@font-face {
  font-family: "Daikon";
  src: local("Daikon"), url(./assets/font/Daikon-Regular.ttf) format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Daikon";
  src: local("Daikon-Bold"), url(./assets/font/Daikon-Bold.ttf) format("truetype");
  font-weight: bold;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Daikon", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Daikon", monospace;
}
